import React from 'react'
import HeroGradient from 'components/hero-image/hero-image-gradient'
import { Helmet } from 'react-helmet'
import Promos from 'components/promos'
import Floater from 'components/floating-grid'
import Content from 'components/category/content'
import Videos from 'components/category/videos'
import Layout from 'components/layout'
import { graphql } from 'gatsby'

const items = [
  {
    link: '/construction/excavators/compact-excavators/',
    imageId: 'excavatorsImage',
    name: 'Compact Excavators',
    alt: 'John Deere Compact Excavators',
  },
]

const ExcavatorsPage = ({ data }) => {
  const { allHutsonPromotion, allDeereOffer, heroImage } = data
  const promos = []
  if (allHutsonPromotion) {
    allHutsonPromotion.nodes.forEach(node => promos.push(node))
  }
  if (allDeereOffer) {
    allDeereOffer.nodes.forEach(node => promos.push(node))
  }
  const gridItems = items.map(item => {
    item.image = data[item.imageId]
    return item
  })
  return (
    <Layout>
      <Helmet>
        <title>John Deere Excavators | Hutson Inc</title>
        <meta
          name='description'
          content='Power. Comfort. Versatility. Find out why a John Deere mini excavator is for you. 2-year/2,000 hour warranty. Find one in stock at a Hutson near you.'
        />
        <script type='application/ld+json'>
          {JSON.stringify({
            '@context': 'http://schema.org',
            '@type': 'BreadcrumbList',
            'itemListElement': [
              {
                '@type': 'ListItem',
                'position': 1,
                'name': 'Hutson Inc',
                'item': 'https://www.hutsoninc.com/',
              },
              {
                '@type': 'ListItem',
                'position': 2,
                'name': 'Construction Equipment',
                'item': 'https://www.hutsoninc.com/construction/',
              },
              {
                '@type': 'ListItem',
                'position': 3,
                'name': 'Excavators',
                'item': 'https://www.hutsoninc.com/construction/excavators/',
              },
            ],
          })}
        </script>
      </Helmet>

      <HeroGradient image={heroImage} heading='Excavators' />

      <Content>
        <Floater items={gridItems} />

        <Promos data={promos} type='Excavator' />

        <Videos type='Excavator' id='zQZgftUEvvw' list='PLUz5fj7f_mw-JxoKOlc6DTTEYHj3p-XuD' />
      </Content>
    </Layout>
  )
}

export const pageQuery = graphql`
  query excavatorsSubcategoryQuery($subcategory: String = "excavators") {
    allHutsonPromotion(
      filter: {
        categoryList: { elemMatch: { category: { eq: $subcategory }, type: { eq: "john-deere" } } }
      }
      sort: { fields: [endDate], order: DESC }
      limit: 3
    ) {
      nodes {
        ...HutsonPromo
      }
    }
    allDeereOffer(filter: { subcategoryList: { in: [$subcategory] } }, limit: 3) {
      nodes {
        ...DeerePromo
      }
    }
    heroImage: file(relativePath: { eq: "headers/compact-excavators-subcategory-header.jpg" }) {
      ...FullWidthImage
    }
    excavatorsImage: file(relativePath: { eq: "construction/excavators.jpg" }) {
      ...FloatingGridImage
    }
  }
`

export default ExcavatorsPage
